<template>
    <el-dialog :visible="visible" width="70%" @close="handleClose" class="dialog" :title="title">
        <el-tabs tab-position="left" style="height: 750px;" @tab-click="handleClick" v-model="activeName"
                 v-if="dataTable.length">
            <el-tab-pane :label="item.createTime + '基础问卷'" :name="index.toString()" v-for="(item,index) in dataTable"
                         :key="index">
                <el-row v-if="item.questionnaireStatus=='3'">
                    <el-form ref="ruleForm" :model="form" label-width="125px" size="small">
                        <el-tabs v-model="activetab">
                            <el-tab-pane label="个人信息" name="1">
                                <my-form :formData="tab1data" :form="form" labelwidth="125px"></my-form>
                            </el-tab-pane>
                            <el-tab-pane label="健康信息" name="2">
                                <health-info-form :form="form" labelwidth="110px"></health-info-form>
                            </el-tab-pane>
                            <el-tab-pane label="家庭信息" name="3">
                                <family-history-form :form="form" labelwidth="80px"></family-history-form>
                            </el-tab-pane>
                            <el-tab-pane label="生活信息" name="4">
                                <life-info-form :form="form" labelwidth="120px"></life-info-form>
                            </el-tab-pane>
                        </el-tabs>
                        <el-row>
                            <el-col :span="4">
                                <el-form-item label="电话沟通" label-width="70px">
                                    <el-checkbox v-model="form.phoneStatus"></el-checkbox>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="沟通医生" label-width="70px">
                                    <el-input v-model="form.callerName">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="沟通记录" label-width="70px">
                            <el-input v-model="form.content" :rows="4" type="textarea">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">同步档案
                            </el-button>
                        </el-form-item>
                    </el-form>

                </el-row>
                <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
        </el-tabs>
        <el-empty description="暂无数据" v-else></el-empty>
    </el-dialog>
</template>

<script>
    import data from "../../../../components/user/data"

    export default {
        props: {
            visible: {
                default: false
            },
            title: {},
        },
        data() {
            return {
                loading: false,
                activeName: "",
                activetab: "1",
                form: {},
                form1: {
                    familyHistory: {
                        "fater": {"value": [], "remark": ""}, "monter": {"value": [], "remark": ""},
                        "brothers": {"value": [], "remark": ""}, "children": {"value": [], "remark": ""},
                        "familyDiseases": {"value": [], "remark": ""}
                    },
                    pastHistory: {
                        "trauma": {"has": null, "list": []},
                        "illness": {"has": null, "list": []},
                        "surgery": {"has": null, "list": []},
                        "transfusion": {"has": null, "list": []}
                    },
                    other: {
                        "all": "", "frist": "", "expose": [], "heredity": 0,
                        "disability": {"value": [], "remark": ""}, "heredityDetail": "",
                        "illnessHostory": "", "drugIrritability": {"value": [], "remark": ""},
                        "foodsIrritability": ""
                    },
                    environment: {"water": "", "energy": "", "toilet": "", "acorral": "", "exhaust": ""},
                    lifeInfo: {"daily": "", "drink": "", "foods": "", "sleep": "", "smoke": "", "style": ""},
                    healthInfo: {"BMI": "", "blood": "", "waist": "", "height": "", "weight": ""},
                    patientInfo:{}
                },
                dataTable: [],
                tab1data: data.tab1form,
                type: 'questionnaire'
            }
        },
        methods: {
            handleClick() {
                this.form = this.dataTable[this.activeName]
                if(this.form.questionnaireStatus !=="3"){
                    this.form = this.form1
                }
            },
            async formSubmit() {
                console.log(this.form)
                // const valid = await this.$refs['ruleForm'].validate().then(res => res).catch(() => {
                //     return false
                // });
                // if (!valid) return
                let dataForm = JSON.stringify(this.form);
                dataForm = JSON.parse(dataForm)
                dataForm.type = this.type
                const resp = await this.$http.post(`/phr/form`, dataForm)
                this.$message[resp.data.status]("提交成功！")
                if (resp.data.status == 'success') {
                    this.$emit('close')
                    this.$emit('update')
                }
            },
            async getData(id) {
                const resp = await this.$http.get(`/questionnaire/${id}?page=1&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.dataTable = resp.data.data
                    if (this.dataTable.length) {
                        this.form = this.dataTable[0]
                        if(this.form.questionnaireStatus !=="3"){
                            this.form = this.form1
                        }
                    }
                }
            },
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
